import React from "react";
import PropTypes from "prop-types";
import Modal from "Util/Modal";
import contractFragments from "../../Queries/graphql/contractFragments";
import NewOrderLink from "./link";
import NewOrderFacilityModal from "./FacilityModal";
import PartnerModal from "./PartnerModal";

class NewOrderModal extends React.Component {
  state = { isOpen: false };

  render() {
    const buttonText = "Add Manual Order";
    const contracts = this.props.contracts.toSorted((a, b) =>
      a.contractCode.localeCompare(b.contractCode)
    );

    if (contracts.length === 1) {
      if (contracts[0].contractType === "Community") {
        return (
          <NewOrderLink
            contract={contracts[0]}
            cssClass="button primary"
            linkText={buttonText}
            closeModal={this.closeModal}
          />
        );
      } else if (contracts[0].contractType === "Hospital") {
        return (
          <NewOrderFacilityModal
            contract={contracts[0]}
            closeParentModal={this.closeModal}
            cssClass="button primary"
            linkText={buttonText}
            currentUser={this.props.currentUser}
          />
        );
      } else {
        return (
          <PartnerModal
            contract={contracts[0]}
            closeParentModal={this.closeModal}
            cssClass="button primary"
            linkText={buttonText}
            currentUser={this.props.currentUser}
          />
        );
      }
    } else {
      return (
        <React.Fragment>
          <button className="button primary" onClick={this.toggleModal}>
            {buttonText}
          </button>
          {this.state.isOpen && (
            <Modal
              show={this.state.isOpen}
              onClose={this.toggleModal}
              header="Contract for the new order"
              className="draft-order-modal"
              renderFooter={() => (
                <a onClick={this.toggleModal} className="button button--back">
                  Cancel
                </a>
              )}
            >
              <ul className="list">
                {contracts.map(contract => (
                  <li key={contract.id} className="list__item">
                    {contract.contractType === "Community" ? (
                      <NewOrderLink
                        contract={contract}
                        closeModal={this.closeModal}
                      />
                    ) : contract.contractType === "Hospital" ? (
                      <NewOrderFacilityModal
                        contract={contract}
                        closeParentModal={this.closeModal}
                        background={false}
                        currentUser={this.props.currentUser}
                      />
                    ) : (
                      <PartnerModal
                        contract={contract}
                        closeParentModal={this.closeModal}
                        background={false}
                        currentUser={this.props.currentUser}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </Modal>
          )}
        </React.Fragment>
      );
    }
  }

  toggleModal = e => {
    e.preventDefault();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };
}

NewOrderModal.propTypes = {
  contracts: PropTypes.arrayOf(contractFragments.object).isRequired,
  currentUser: PropTypes.object
};

export default NewOrderModal;
