// Sourced from:
// https://css-tricks.com/using-react-portals-to-render-children-outside-the-dom-hierarchy/
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

class Portal extends React.Component {
  constructor() {
    super();
    // 1: Create a new div that wraps the component
    this.el = document.createElement("div");
  }
  static defaultProps = {
    background: true
  };

  // 2: Append the element to the DOM when it mounts
  componentDidMount = () => {
    document.getElementById("modal-portal").appendChild(this.el);
  };
  // 3: Remove the element when it unmounts
  componentWillUnmount = () => {
    document.getElementById("modal-portal").removeChild(this.el);
  };
  render() {
    // 4: Render the element's children in a Portal
    const { children } = this.props;
    return ReactDOM.createPortal(
      <React.Fragment>
        {this.props.background && <section className={`modal-background`} />}
        {children}
      </React.Fragment>,
      this.el
    );
  }
}

Portal.propTypes = {
  background: PropTypes.bool
};

export default Portal;
