import React from "react";
import PropTypes from "prop-types";
import Modal from "Util/Modal";
import contractFragments from "../../Queries/graphql/contractFragments";
import NewOrderLink from "./link";
import FacilitiesTable from "./FacilitiesTable";

class NewOrderFacilityModal extends React.Component {
  state = { isOpen: !!this.props.partnerId };

  static defaultProps = {
    background: true
  };

  render() {
    const { contract, currentUser } = this.props;

    return (
      <React.Fragment>
        <a
          href="#"
          onClick={e => this.toggleModal(e)}
          className={this.props.cssClass}
        >
          {this.props.linkText || contract.contractCode}
        </a>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={() => this.closeModal(true)}
            header="Facility for the new order"
            className="facilities-modal"
            background={this.props.background}
            renderFooter={() => (
              <a
                onClick={() => this.closeModal(false)}
                className="button button--back"
              >
                Back
              </a>
            )}
          >
            {contract.contractType === "Hospital" && (
              <div className="margin-bottom-base">
                <NewOrderLink
                  contract={contract}
                  linkText="Create Discharge Order"
                  cssClass="button button--white button--small"
                  queryParams="discharge=true"
                  closeModal={() => this.closeModal(true)}
                />
              </div>
            )}
            <FacilitiesTable
              contract={contract}
              closeModal={() => this.closeModal(true)}
              partnerId={this.props.partnerId}
              currentUser={currentUser}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }

  toggleModal = e => {
    e.preventDefault();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  closeModal = closeParent => {
    this.setState({ isOpen: false });
    if (this.props.onClose) this.props.onClose();
    if (closeParent) {
      this.props.closeParentModal();
    }
  };
}

NewOrderFacilityModal.propTypes = {
  contract: contractFragments.object.isRequired,
  closeParentModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  cssClass: PropTypes.string,
  linkText: PropTypes.string,
  background: PropTypes.bool,
  partnerId: PropTypes.number,
  currentUser: PropTypes.object
};

export default NewOrderFacilityModal;
