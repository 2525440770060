import React from "react";
import Table from "./Table";

import { showManualOrder } from "./../BusRules/access";

import GridPropsQuery from "../../lib/AgGrid/GridPropsQuery";
import NewOrderModal from "./NewOrder/modal";

class OrderListIndex extends React.Component {
  render() {
    const gridPropsFields = [
      {
        dataKey: "staffMembers",
        gqlQueryString: `
          staffMembers {
            id
            name
            headOfficeContractIds
          }`
      },
      {
        dataKey: "draftOrderContracts",
        gqlQueryString: `
          draftOrderContracts {
            id
            contractCode
            contractType
            currentUserRole
          }`
      },
      {
        dataKey: "currentUser",
        gqlQueryString: `
          currentUser {
            headOfficeContracts
            partnerContracts
            gridFilters(grid: "orders") {
              id
              name
              filterModel
              default
            }
          }`
      }
    ];

    return (
      <GridPropsQuery
        additionalFields={gridPropsFields}
        render={({
          contractCodes,
          currentUser,
          staffMembers,
          draftOrderContracts
        }) => (
          <React.Fragment>
            <section className="main_section">
              <article className="main_section__content">
                <header className="main_section__header">
                  <h1 className="main_section__title">Orders</h1>
                  {showManualOrder(currentUser) && (
                    <NewOrderModal
                      contracts={draftOrderContracts}
                      currentUser={currentUser}
                    />
                  )}
                </header>
                <Table
                  contractCodes={contractCodes}
                  staffMembers={staffMembers}
                  currentUser={currentUser}
                />
              </article>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

OrderListIndex.propTypes = {};

export default OrderListIndex;
