import React from "react";

import Modal from "Util/Modal";

import PropTypes from "prop-types";
import PartnersTable from "./PartnersTable";
import contractFragments from "../../Queries/graphql/contractFragments";
import NewOrderFacilityModal from "./FacilityModal";

class PartnerModal extends React.Component {
  state = { isOpen: false, partner: null };

  static defaultProps = {
    background: true
  };

  render() {
    const { contract, currentUser, cssClass, linkText } = this.props;
    return (
      <React.Fragment>
        <a href="#" onClick={e => this.open(e)} className={cssClass}>
          {linkText || contract.contractCode}
        </a>
        {this.state.isOpen && (
          <Modal
            show={this.state.isOpen}
            onClose={() => this.close(true)}
            header="Preferred Partner for the new order"
            className="draft-order-partner-modal"
            background={this.props.background}
            renderFooter={() => (
              <a
                onClick={() => this.close(false)}
                className="button button--back"
              >
                Back
              </a>
            )}
          >
            {!this.state.partner && (
              <PartnersTable
                contract={contract}
                currentUser={currentUser}
                onSelect={this.partnerSelected}
              />
            )}
            {this.state.partner && (
              <NewOrderFacilityModal
                contract={contract}
                closeParentModal={() => this.close(true)}
                onClose={() => this.setState({ partner: null })}
                cssClass="button primary test"
                background={false}
                partnerId={this.state.partner.id}
                currentUser={currentUser}
              />
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }

  open = e => {
    e.preventDefault();
    this.setState({ isOpen: true });
  };

  close = closeParent => {
    this.setState({ isOpen: false, partner: null });
    if (closeParent) {
      this.props.closeParentModal();
    }
  };

  partnerSelected = selection => {
    this.setState({ partner: selection });
  };
}

PartnerModal.propTypes = {
  contract: contractFragments.object.isRequired,
  closeParentModal: PropTypes.func.isRequired,
  cssClass: PropTypes.string,
  linkText: PropTypes.string,
  background: PropTypes.bool,
  currentUser: PropTypes.object.isRequired
};

export default PartnerModal;
