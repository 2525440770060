import React from "react";
import PropTypes from "prop-types";
import Portal from './Portal';

// For now this is a very simple modal
// Do we import a library or a better Modal component

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  // Example useage:
  // <Modal
  //   show={this.state.isOpen}
  //   onClose={() => this.setState({isOpen: false})}
  //   header="Some header"
  //   renderFooter={() => <div>Some test in a div?</div>}
  // >model contents JSX</Modal>

  render() {
    const {
      show,
      header,
      headerSmall,
      onClose,
      children,
      renderFooter
    } = this.props;
    // Render nothing if the "show" prop is false
    if (!show) {
      return null;
    }

    if (window.pageYOffset > 250) {
      window.scrollTo(0, 250);
    }

    return (
      <React.Fragment>
        <Portal background={this.props.background}>
          <section className={`modal is-active ${this.props.className}`}>
            <div className="modal__inner">
              <header className="modal__header">
                <h3 className="modal__header__heading">
                  {header}
                  {headerSmall && <small>{headerSmall}</small>}
                </h3>
                <a className="modal__header__close" title="Close" onClick={onClose}>
                  <svg viewBox="0 0 100 100">
                    <g>
                      <path
                        d="M39.3736839,39.3736839 L39.3736839,6.22591975 C39.3736839,0.703072256 43.8508364,-3.77408025 49.3736839,-3.77408025 L50.3943895,-3.77408025 C55.917237,-3.77408025 60.3943895,0.703072256 60.3943895,6.22591975 L60.3943895,39.3736839 L93.5421536,39.3736839 C99.0650011,39.3736839 103.542154,43.8508364 103.542154,49.3736839 L103.542154,50.3943895 C103.542154,55.917237 99.0650011,60.3943895 93.5421536,60.3943895 L60.3943895,60.3943895 L60.3943895,93.5421536 C60.3943895,99.0650011 55.917237,103.542154 50.3943895,103.542154 L49.3736839,103.542154 C43.8508364,103.542154 39.3736839,99.0650011 39.3736839,93.5421536 L39.3736839,60.3943895 L6.22591975,60.3943895 C0.703072256,60.3943895 -3.77408025,55.917237 -3.77408025,50.3943895 L-3.77408025,49.3736839 C-3.77408025,43.8508364 0.703072256,39.3736839 6.22591975,39.3736839 L39.3736839,39.3736839 Z"
                        transform="translate(49.884037, 49.884037) rotate(-315.000000) translate(-49.884037, -49.884037) "
                      />
                    </g>
                  </svg>
                </a>
              </header>
              <div className="modal__body">{children}</div>
              {renderFooter && (
                <footer className="modal__footer">{renderFooter()}</footer>
              )}
            </div>
          </section>
        </Portal>
      </React.Fragment>
    );
  }

  escFunction = event => {
    if (event.key === "Escape") {
      this.props.onClose();
    }
  };
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  background: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.string,
  headerSmall: PropTypes.string,
  renderFooter: PropTypes.func
};

export default Modal;
