import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { AgGridReact } from "ag-grid-react";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import contractFragments from "../../Queries/graphql/contractFragments";

const PartnersTable = ({ contract, currentUser, onSelect }) => {
  // Grid setup
  const [columnDefs] = useState([
    {
      headerName: "",
      colId: "select",
      pinned: "left",
      width: 70,
      sortable: false,
      filter: false,
      cellRenderer: params => (
        <button
          onClick={() => onSelect(params.data)}
          className="button button--tiny button--white"
        >
          Select
        </button>
      )
    },
    {
      headerName: "Name",
      field: "name",
      pinned: "left",
      width: 180,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Partner Type",
      field: "partnerType",
      width: 150,
      filter: "agSetColumnFilter"
    },
    {
      headerName: "Service Description",
      field: "serviceDesc",
      width: 200,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Status",
      field: "status",
      width: 120,
      filter: "agSetColumnFilter"
    },
    {
      headerName: "Street Address",
      field: "streetAddress",
      width: 220,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "City",
      field: "city",
      width: 150,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "State",
      field: "state",
      width: 100,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]
      }
    },
    {
      headerName: "Postcode",
      field: "postcode",
      width: 100,
      filter: "agTextColumnFilter"
    }
  ]);

  const [defaultColDef] = useState({
    resizable: true,
    sortable: true,
    filter: true
  });

  const partnersQuery = useMemo(() => {
    if (contract.currentUserRole === "wholesale") {
      return gql`
        query WholesalePartners(
          $contractId: Int!
          $agGridParams: RequestParamInput!
        ) {
          wholesalePartners(contractId: $contractId, agGridParams: $agGridParams) {
            id
            name
            streetAddress
            status
            partnerType
            city
            state
            postcode
            serviceDesc
          }
        }
      `;
    } else {
      return gql`
        query NetworkPartners(
          $contractId: Int!
          $agGridParams: RequestParamInput!
        ) {
          networkPartners(contractId: $contractId, agGridParams: $agGridParams) {
            id
            name
            streetAddress
            status
            partnerType
            city
            state
            postcode
            serviceDesc
          }
        }
      `;
    }
  }, [contract]);

  const responseDataKey =
    contract.currentUserRole === "wholesale"
      ? "wholesalePartners"
      : "networkPartners";

  return (
    <GraphqlWrapper>
      <Query
        query={partnersQuery}
        variables={{
          contractId: contract.id,
          agGridParams: {}
        }}
      >
        {({ loading, error, data }) => {
          useEffect(() => {
            // Handle single wholesale case after data is fetched
            if (
              !loading &&
              !error &&
              contract.currentUserRole === "wholesale" &&
              data &&
              data[responseDataKey].length === 1
            ) {
              onSelect(data[responseDataKey][0]); // Move logic into useEffect
            }
          }, [loading, error, data]); // Dependencies for useEffect

          if (loading || !data) return <div>Loading...</div>;

          return (
            <div className="ag-theme-balham ag-grid-wrapper half-height">
              <AgGridReact
                rowData={data[responseDataKey] || []}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
              />
            </div>
          );
        }}
      </Query>
    </GraphqlWrapper>
  );
};

PartnersTable.propTypes = {
  contract: contractFragments.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default PartnersTable;
