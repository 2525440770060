import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import facilityFragments from "./facilityFragments";

const fragments = {
  query: gql`
    fragment ContractQuery on Contract {
      id
      contractCode
      contractType
    }
  `,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contractCode: PropTypes.string.isRequired,
    contractType: PropTypes.string.isRequired,
    facilities: PropTypes.arrayOf(facilityFragments.object),
    currentUserRole: PropTypes.string
  })
};

export default fragments;
