import React from "react";
import PropTypes from "prop-types";

import EditTextField from "Form/EditTextField";
import RoField from "Form/RoField";
import EditDropdown from "Form/EditDropdown";

import { OrderObject } from "./../../Queries/OrderQuery";
import { isOnDvaContract, isNEDOrder } from "../../BusRules/access";
import { isAllowedMixedOrderLines } from "../../BusRules/orders";

class AddNonContractedItemForm extends React.Component {
  state = { disabled: false };
  static defaultProps = {
    isAddFreight: false
  };

  render() {
    const { uniqueScheduleCodes, sizes, requiredFields, item } = this.props;

    const ignoreDecimal = e => {
      if (e.key === ".") {
        e.preventDefault();
      }
    };

    return (
      <form onSubmit={() => this.onSubmit()} className="form">
        <div className={"display-flex"}>
          <fieldset className="fieldset fieldset--fullwidth field_with_errors">
            <RoField
              name="sku"
              type="text"
              value={item.sku}
              labelText="Cat number"
            />
            <EditDropdown
              name="size"
              onChange={this.handleChange}
              value={item.size}
              labelText="Size"
              required={requiredFields.includes("size")}
              options={sizes}
            />
            <EditTextField
              name="description"
              onChange={this.handleChange}
              type="text"
              value={item.description}
              labelText="Description"
              required={requiredFields.includes("description")}
            />
            {isOnDvaContract(this.props.order) && (
              <EditDropdown
                name="rapScheduleCode"
                onChange={this.handleChange}
                type="text"
                value={item.rapScheduleCode}
                labelText="Schedule ID"
                required={requiredFields.includes("rapScheduleCode")}
                options={uniqueScheduleCodes}
              />
            )}
            {isNEDOrder(this.props.order) && (
              <EditTextField
                name="webLineId"
                onChange={this.handleChange}
                type="text"
                value={item.webLineId}
                pattern="[0-9]{7,10}"
                title="7-10 digits"
                labelText="Web ID"
                required={requiredFields.includes("webLineId")}
              />
            )}
            <EditDropdown
              name="saleType"
              onChange={this.handleChange}
              type="text"
              value={item.saleType}
              labelText="Sale Type"
              required={requiredFields.includes("saleType")}
              options={this.saleTypeOptions()}
            />
            <RoField
              name="unit"
              type="text"
              value={item.unit}
              labelText="UOM"
            />
            <EditTextField
              name="quantity"
              onChange={this.handleChange}
              onKeyDown={ignoreDecimal}
              type="number"
              step="1"
              value={item.quantity}
              required={requiredFields.includes("quantity")}
              labelText="Quantity"
            />
            <EditTextField
              name="sellUnitPriceExc"
              onChange={this.handleChange}
              type="number"
              min={0}
              step="0.01"
              value={item.sellUnitPriceExc}
              required={requiredFields.includes("sellUnitPriceExc")}
              labelText="Contract Unit $ (exc GST)"
            />
            <EditTextField
              name="buyUnitPriceExc"
              onChange={this.handleChange}
              type="number"
              min={0}
              step="0.01"
              value={item.buyUnitPriceExc}
              labelText="NP Unit $ (exc GST)"
              required={requiredFields.includes("buyUnitPriceExc")}
            />
            <RoField
              name="gstApplicable"
              type="text"
              value={item.gstApplicable}
              labelText="GST"
            />
          </fieldset>
        </div>
        <div className="form__actions">
          <button
            className="button button--back"
            onClick={this.props.resetGpProduct}
          >
            Reselect item
          </button>

          <input
            type="submit"
            name="commit"
            value="Add to order"
            disabled={this.isSaveDisabled()}
            className="button primary"
          />
        </div>
      </form>
    );
  }

  // Updated rule for sale type is:
  // - if we're adding freight then only "FREIGHT" can be selected
  // - if the order is allowed mixed then an array of [BUY, HIRE]
  // - if the order is a hire order then only HIRE items can be added
  // - otherwise only BUY items can be added
  saleTypeOptions = () => {
    const { order, isAddFreight, item } = this.props;

    if (isAddFreight || item.sku.startsWith("AAF")) {
      return ["FREIGHT"];
    }
    const lineTypes = isAllowedMixedOrderLines(order)
      ? ["BUY", "HIRE"]
      : [order.saleType === "HIRE" ? "HIRE" : "BUY"];
    return lineTypes;
  };
  handleChange = (field, value) => this.props.handleChange(field, value);

  saleTypeInvalid = () => {
    return !this.saleTypeOptions().includes(this.props.item.saleType);
  };

  requiredItemMissing = () =>
    this.props.requiredFields.map(f => this.props.item[f]).filter(v => !v)
      .length > 0 || this.saleTypeInvalid();

  isSaveDisabled = () => {
    return this.disabled || this.requiredItemMissing();
  };

  onSubmit = () => {
    const { order, update, item } = this.props;
    this.setState({ disabled: true });
    item.quantity = parseInt(item.quantity);
    item.sellUnitPriceExc = Number(item.sellUnitPriceExc);
    item.buyUnitPriceExc = Number(item.buyUnitPriceExc);
    update({
      variables: {
        id: order.id,
        input: item
      }
    });
    this.props.complete();
  };
}

AddNonContractedItemForm.propTypes = {
  order: OrderObject.isRequired,
  isAddFreight: PropTypes.bool,
  item: PropTypes.object,
  resetGpProduct: PropTypes.func.isRequired,
  complete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  uniqueScheduleCodes: PropTypes.array,
  sizes: PropTypes.arrayOf(PropTypes.string),
  requiredFields: PropTypes.array.isRequired
};

AddNonContractedItemForm.defaultProps = {
  uniqueScheduleCodes: []
};

export default AddNonContractedItemForm;
