import React from "react";
import PropTypes from "prop-types";

import { gql } from "@apollo/client";
import {Mutation} from "@apollo/client/react/components";

import orderFragments from "./../Queries/graphql/orderFragments";
import orderLinesAndEvents from "./../Queries/graphql/orderLinesAndEvents";
import orderTotalsFragments from "./../Queries/graphql/orderTotalsFragments";
import orderAllocationsFragments from "./../Queries/graphql/orderAllocationsFragments";
import { lineStatuses, validDeliveredOn } from "../BusRules/orderLines";

const UPDATE_ORDER_LINES = gql`
  mutation updateOrderLines($id: ID!, $input: UpdateOrderLinesInput!) {
    updateOrderLines(id: $id, input: $input) {
      order {
        ...OrderFields
        ...OrderLinesAndEventsQuery
        orderAllocations {
          ...OrderAllocationsQuery
        }
        orderTotals {
          ...OrderTotalQuery
        }
        client {
          id
          hireStartOn
        }
      }
      errors {
        path
        message
      }
    }
  }
  ${orderFragments.query}
  ${orderLinesAndEvents.query}
  ${orderTotalsFragments.query}
  ${orderAllocationsFragments.query}
`;

class TableUpdater extends React.Component {
  state = { errors: null };
  render() {
    const { order, render, disableUIFunc } = this.props;
    const { errors } = this.state;
    return (
      <Mutation
        mutation={UPDATE_ORDER_LINES}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(update, { loading, error }) => {
          const saveLines = (order, lines) => {
            if (this.onlyFreightRemaining(order, lines)) {
              window.alert(
                "You cannot leave only freight lines undelivered. Please mark all freight lines delivered."
              );
              return false;
            }
            if (!this.allDeliveryDatesValid(order, lines)) {
              window.alert("Invalid delivery date");
              return false;
            }
            disableUIFunc(true);
            const orderLines = lines.map(line => this.whitelistedInput(line));
            update({
              variables: {
                id: order.id,
                input: {
                  orderLines
                }
              }
            });
          };
          return render({ order, saveLines, errors });
        }}
      </Mutation>
    );
  }

  onlyFreightRemaining = (order, editedLines) => {
    if (order.trial) return false;
    let statusesChanged = false;
    for (const editedLine of editedLines) {
      if (editedLine.status) {
        statusesChanged = true;
        break;
      }
    }
    if (!statusesChanged) return false;

    const undeliveredLines = order.orderLines.filter(line => {
      let lineStatus = line.status;
      editedLines.forEach(editedLine => {
        if (editedLine.id === line.id && editedLine.status) {
          lineStatus = editedLine.status;
        }
      });
      return lineStatuses.SELECTABLE_LIST.includes(lineStatus);
    });
    return (
      undeliveredLines.length !== 0 &&
      undeliveredLines.every(line => line.saleType === "FREIGHT")
    );
  };

  allDeliveryDatesValid = (order, editedLines) => {
    let allValid = true;
    editedLines.forEach(editedLine => {
      const deliveredOn = this.newDeliveredOn(order, editedLine);
      if (
        ["Delivered", "On Hire"].includes(editedLine.status) &&
        (!deliveredOn || !validDeliveredOn(order, deliveredOn))
      ) {
        allValid = false;
      }
    });
    return allValid;
  };

  newDeliveredOn = (order, editedLine) => {
    // If deliveredOn is not edited it won't be included in editedLine
    let deliveredOn = editedLine.deliveredOn;
    if (!deliveredOn) {
      deliveredOn = order.orderLines.find(line => line.id === editedLine.id)
        .deliveredOn;
    }
    return deliveredOn;
  };

  whitelistedInput = line => {
    const input = {};
    const whitelist = [
      "id",
      "comment",
      "quantity",
      "sellUnitPriceExc",
      "buyUnitPriceExc",
      "gst",
      "clientIsEligible",
      "status",
      "etaOn",
      "deliveredOn",
      "pickedUpOn",
      "hireEndOn",
      "providerNotified",
      "assetDetails"
    ];

    whitelist.map(key => {
      if (line[key] !== undefined) {
        if (key === "quantity") {
          input[key] = parseInt(line[key]);
        } else if (key === "gst") {
          input["gstApplicable"] = line[key] === "Yes";
        } else if (["sellUnitPriceExc", "buyUnitPriceExc"].includes(key)) {
          input[key] = line[key].toString();
        } else {
          input[key] = line[key];
        }
      }
    });
    return input;
  };

  onError = error => {
    console.log("onError occurred");
    console.log(error);
    this.props.disableUIFunc(false);
  };

  onCompleted = data => {
    if (data.updateOrderLines && data.updateOrderLines.errors.length > 0) {
      this.setState({ errors: data.updateOrderLines.errors });
    } else {
      this.setState({ errors: null });
    }
    if (this.props.onCompleted) {
      this.props.onCompleted(data);
    }
    this.props.disableUIFunc(false);
  };
}

TableUpdater.propTypes = {
  order: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  disableUIFunc: PropTypes.func.isRequired
};

export default TableUpdater;
